import React from "react"
import { Link } from "gatsby"

import Layout from "layout/layout"
// import Image from "components/image"
import SEO from "components/seo"

const PagePadding = {
  padding: `0 0 40px 0`,
}

const ThankYou = () => (
  <Layout>
    <div className="container" style={PagePadding}>
      <div className="row">
        <div className="col-12">
          <SEO title={`Thank You`} />
          <h1>Thanks for signing up</h1>
          <h3>We're excited to have you on the list!</h3>
          <p>If you haven't already, these might be great places to check out next:</p>

          <h5>Step 1</h5>
          <p>Check out the <Link to={`/resources`}>resources tab</Link>. There are curated collections of books, online resources, and great people to follow in the design community.</p>

          <h5>Step 2</h5>
          <p>Check out the <Link to={`/blog`}>blog</Link>. I'll be sharing my learnings on a daily and weekly basis there with new posts about everything from shower thoughts about design to in-depth lessons learned from my own journey through design.</p>

          <h5>Step 3</h5>
          <p>Reach out and say hi on <a href={`https://www.instagram.com/deecondesign`}>Instagram</a>, <a href={`https://www.twitter.com/deecondesign`}>Twitter</a>, or <a href={`mailto:patrick@deconstructingdesign.com`}>email</a>. I'm always open to talk to anyone looking to learn more about design to experienced designers who are looking for an opportunity to drop some knowledge.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThankYou
